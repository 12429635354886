import {
    type EntityViewConfig,
    EntityCoreTypeEnum,
    EntityViewConfigTypeEnum,
    EntityViewConfigValueTypeEnum,
} from '@prisma/client'
import type { Exact } from 'type-fest'

//
// Common configuration and definitions
//

export const EntityViewPageNameEnum = {
    opportunityList: 'opportunityList',
    dataProductList: 'dataProductList',
    opportunityReport: 'opportunityReport',
    dataProductReport: 'dataProductReport',
    businessLineage: 'businessLineage',
} as const
export type EntityViewPageNameEnumType = keyof typeof EntityViewPageNameEnum

export const FilterOperatorEnum = {
    is: 'is',
    isNot: 'isNot',
    includeAnyOf: 'includeAnyOf',
    includeAllOf: 'includeAllOf',
    excludeIfAnyOf: 'excludeIfAnyOf',
    excludeIfAllOf: 'excludeIfAllOf',
    isGreaterThan: 'isGreaterThan',
    isLessThan: 'isLessThan',
    isEqualTo: 'isEqualTo',
    isNotEqualTo: 'isNotEqualTo',
    greaterOrEqualTo: 'greaterOrEqualTo',
    lessOrEqualTo: 'lessOrEqualTo',
    contains: 'contains',
    doesNotContain: 'doesNotContain',
    isEmpty: 'isEmpty',
    isNotEmpty: 'isNotEmpty',
    isBefore: 'isBefore',
    isAfter: 'isAfter',
} as const
export type FilterOperatorEnumType = keyof typeof FilterOperatorEnum

export const OptionKeyEnum = {
    order: 'order',
} as const
export type OptionKeyEnumType = keyof typeof OptionKeyEnum

export const OrderOperatorEnum = {
    asc: 'asc',
    desc: 'desc',
} as const
export type OrderOperatorEnumType = keyof typeof OrderOperatorEnum

export const UIKeyEnum = {
    groupBy: 'groupBy',
    showEmptyGroups: 'showEmptyGroups',
    showUnassignedGroups: 'showUnassignedGroups',
    selectedGroups: 'selectedGroups',
    entityProperties: 'entityProperties',
    layout: 'layout',
} as const
export type UIKeyEnumType = keyof typeof UIKeyEnum

export const sharedUIDefinitions = {
    [UIKeyEnum.groupBy]: EntityViewConfigValueTypeEnum.string,
    [UIKeyEnum.showEmptyGroups]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.showUnassignedGroups]: EntityViewConfigValueTypeEnum.boolean,
    [UIKeyEnum.selectedGroups]: EntityViewConfigValueTypeEnum.json,
    [UIKeyEnum.entityProperties]: EntityViewConfigValueTypeEnum.json,
    [UIKeyEnum.layout]: EntityViewConfigValueTypeEnum.string,
} as const satisfies Record<UIKeyEnumType, EntityViewConfigValueTypeEnum>

type ValueFieldType = 'valueString' | 'valueStringAry' | 'valueNumber' | 'valueBoolean' | 'valueDate' | 'valueJson'
export const valueTypeToAttributeName: Record<EntityViewConfigValueTypeEnum, ValueFieldType | undefined> = {
    [EntityViewConfigValueTypeEnum.string]: 'valueString',
    [EntityViewConfigValueTypeEnum.string_ary]: 'valueStringAry',
    [EntityViewConfigValueTypeEnum.number]: 'valueNumber',
    [EntityViewConfigValueTypeEnum.boolean]: 'valueBoolean',
    [EntityViewConfigValueTypeEnum.date]: 'valueDate',
    [EntityViewConfigValueTypeEnum.json]: 'valueJson',
    [EntityViewConfigValueTypeEnum.none]: undefined,
} as const

export type EntityViewFilter<Keys> = CheckedKeys<
    keyof Pick<EntityViewConfig, 'key' | 'filterOperator'>,
    {
        key: Keys
        filterOperator: FilterOperatorEnumType
    }
> &
    Pick<EntityViewConfig, 'id' | 'filterId' | ValueFieldType>

export const LayoutEnum = {
    card: 'card',
    list: 'list',
    table: 'table',
} as const
export type LayoutEnum = keyof typeof LayoutEnum
export type UIOptionGroupBy<GroupKeys> = {
    id: string
    key: (typeof UIKeyEnum)['groupBy']
    valueString: GroupKeys
    filterId: string
    filterOperator: OrderOperatorEnumType
}
export type UIOptionShowEmptyGroups = {
    id: string
    key: (typeof UIKeyEnum)['showEmptyGroups']
    valueBoolean: boolean
}
export type UIOptionShowUnassignedGroups = {
    id: string
    key: (typeof UIKeyEnum)['showUnassignedGroups']
    valueBoolean: boolean
}
export type UIOptionSelectedGroups = {
    id: string
    key: (typeof UIKeyEnum)['selectedGroups']
    valueJson: EntityProperty[] | null
}
export type UIOptionLayout = {
    id: string
    key: (typeof UIKeyEnum)['layout']
    valueString: LayoutEnum
}

export const EntityPropertyType = {
    StaticProperty: 'StaticProperty',
    CustomProperty: 'CustomProperty',
    Assessment: 'Assessment',
    ValueScoreCriterion: 'ValueScoreCriterion',
    // Used if the type isn't imprtant, e.g. for grouping
    Ignore: 'Ignore',
} as const
export type EntityPropertyType = keyof typeof EntityPropertyType

type _EntityProperty = {
    id: string
    selected: boolean
    type: EntityPropertyType
}
export type EntityProperty = Exact<_EntityProperty, _EntityProperty>

export type UIOptionEntityProperties = {
    id: string
    key: (typeof UIKeyEnum)['entityProperties']
    valueJson: EntityProperty[]
}

export type EntityViewUIOption<GroupKeys> =
    | UIOptionGroupBy<GroupKeys>
    | UIOptionShowEmptyGroups
    | UIOptionShowUnassignedGroups
    | UIOptionSelectedGroups
    | UIOptionLayout
    | UIOptionEntityProperties

export type EntityViewQueryOption<OrderKeys> = Pick<EntityViewConfig, 'id'> & {
    key: (typeof OptionKeyEnum)['order']
    filterOperator: OrderOperatorEnumType
    valueString: OrderKeys
    filterId?: string
}

export type EntityViewParams<FilterKeys, OrderKeys, GroupKeys> = CheckedKeys<
    EntityViewConfigTypeEnum,
    {
        [EntityViewConfigTypeEnum.filter]: EntityViewFilter<FilterKeys>[]
        [EntityViewConfigTypeEnum.queryOption]: EntityViewQueryOption<OrderKeys>[]
        [EntityViewConfigTypeEnum.uiOption]: EntityViewUIOption<GroupKeys>[]
    }
>

export function isFilter<FilterKeys extends string>(
    filter: object,
    definitions: FilterDefinitionsType<FilterKeys>
): filter is EntityViewFilter<FilterKeys> {
    const isValidFilter =
        'id' in filter &&
        'key' in filter &&
        'filterOperator' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.filterOperator === 'string' &&
        filter.filterOperator in FilterOperatorEnum &&
        filter.key in definitions &&
        filter.filterOperator in definitions[filter.key as FilterKeys]

    if (!isValidFilter) return false

    const valueType: EntityViewConfigValueTypeEnum =
        definitions[filter.key as FilterKeys][filter.filterOperator as FilterOperatorEnumType]!
    const attributeName = valueTypeToAttributeName[valueType]

    if (valueType === EntityViewConfigValueTypeEnum.none) return true
    // Redundant check, but TypeScript doesn't see connection between valueType and attributeName,
    // for valueType other than none, attributeName is always defined
    if (!attributeName) return true

    if (!(attributeName in filter)) return false

    const filterValue = (filter as unknown as Record<typeof attributeName, unknown>)[attributeName]
    return (
        (valueType !== EntityViewConfigValueTypeEnum.string_ary && filterValue !== null) ||
        (valueType === EntityViewConfigValueTypeEnum.string_ary && Array.isArray(filterValue) && !!filterValue.length)
    )
}

export function isQueryOption<OrderKeys extends string>(
    filter: object,
    orderKeys: StringEnum<OrderKeys>
): filter is EntityViewQueryOption<OrderKeys> {
    return (
        'id' in filter &&
        'key' in filter &&
        'filterOperator' in filter &&
        'valueString' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.filterOperator === 'string' &&
        typeof filter.valueString === 'string' &&
        filter.key === OptionKeyEnum.order &&
        filter.filterOperator in OrderOperatorEnum &&
        filter.valueString in orderKeys
    )
}

export function isUIOptionGroupBy<GroupKeys extends string>(
    filter: object,
    groupKeys: StringEnum<GroupKeys>
): filter is UIOptionGroupBy<GroupKeys> {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueString' in filter &&
        'filterId' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueString === 'string' &&
        typeof filter.filterId === 'string' &&
        filter.key === UIKeyEnum.groupBy &&
        filter.valueString in groupKeys &&
        'filterOperator' in filter &&
        typeof filter.filterOperator === 'string' &&
        filter.filterOperator in OrderOperatorEnum
    )
}

export function isUIOptionShowEmptyGroups(filter: object): filter is UIOptionShowEmptyGroups {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueBoolean' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueBoolean === 'boolean' &&
        filter.key === UIKeyEnum.showEmptyGroups
    )
}

export function isUIOptionShowUnassignedGroups(filter: object): filter is UIOptionShowUnassignedGroups {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueBoolean' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueBoolean === 'boolean' &&
        filter.key === UIKeyEnum.showUnassignedGroups
    )
}

export function isUIOptionSelectedGroups(filter: object): filter is UIOptionSelectedGroups {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueJson' in filter &&
        typeof filter.key === 'string' &&
        filter.key === UIKeyEnum.selectedGroups
    )
}

export function isUIOptionEntityProperties(filter: object): filter is UIOptionEntityProperties {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueJson' in filter &&
        typeof filter.key === 'string' &&
        filter.key === UIKeyEnum.entityProperties &&
        filter.valueJson !== null
    )
}

export function isUIOptionLayout(filter: object): filter is UIOptionLayout {
    return (
        'id' in filter &&
        'key' in filter &&
        'valueString' in filter &&
        typeof filter.key === 'string' &&
        typeof filter.valueString === 'string' &&
        filter.key === UIKeyEnum.layout &&
        filter.valueString in LayoutEnum
    )
}

//
// Opportunities definitions
//

// TODO: Rename to OpportunityFilterEnum ONE-1623
export const OpportunityFilterKeyEnum = {
    title: 'title',
    keyword: 'keyword',
    valuePotential: 'valuePotential',
    priority: 'priority',
    phase: 'phase',
    businessGoal: 'businessGoal',
    owner: 'owner',
    stakeholder: 'stakeholder',
    businessTeam: 'businessTeam',
    dataTeam: 'dataTeam',
    metric: 'metric',
    area: 'area',
    // Prioritization
    valueScore: 'valueScore',
    valueScoreCriterion: 'valueScoreCriterion',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyPerson: 'customPropertyPerson',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyText: 'customPropertyText',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
    // Dev purpose only
    createdById: 'createdById',
} as const
export type OpportunityFilterKeyEnumType = keyof typeof OpportunityFilterKeyEnum

export const OpportunityOrderEnum = {
    title: 'title',
    valuePotential: 'valuePotential',
    valueScore: 'valueScore',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    lastCreated: 'lastCreated',
    lastUpdated: 'lastUpdated',
    customPropertySelect: 'customPropertySelect',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyText: 'customPropertyText',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
} as const
export type OpportunityOrderEnumType = keyof typeof OpportunityOrderEnum

export const defaultOpportunityOrder = {
    key: OptionKeyEnum.order,
    valueString: OpportunityOrderEnum.valuePotential,
    filterOperator: OrderOperatorEnum.desc,
} as const

export const OpportunityGroupEnum = {
    ungrouped: 'ungrouped',
    dataProduct: 'dataProduct',
    phase: 'phase',
    priority: 'priority',
    keyword: 'keyword',
    valuePotential: 'valuePotential',
    valuePotentialCluster: 'valuePotentialCluster',
    valueScore: 'valueScore',
    businessGoal: 'businessGoal',
    owner: 'owner',
    stakeholder: 'stakeholder',
    businessTeam: 'businessTeam',
    dataTeam: 'dataTeam',
    metric: 'metric',
    area: 'area',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyPerson: 'customPropertyPerson',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
} as const
export type OpportunityGroupEnumType = keyof typeof OpportunityGroupEnum

export const OpportunityParamKeyEnum = {
    ...OpportunityFilterKeyEnum,
    ...OpportunityGroupEnum,
    // Used by table layout
    customProperty: 'customProperty',
} as const
export type OpportunityParamKeyEnumType = keyof typeof OpportunityParamKeyEnum

export const opportunityFilterDefinitions = {
    [OpportunityFilterKeyEnum.title]: {
        [FilterOperatorEnum.contains]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.doesNotContain]: EntityViewConfigValueTypeEnum.string,
    },
    [OpportunityFilterKeyEnum.valuePotential]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.businessGoal]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.keyword]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },

    [OpportunityFilterKeyEnum.area]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.metric]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.priority]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [OpportunityFilterKeyEnum.phase]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [OpportunityFilterKeyEnum.valueScore]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.valueScoreCriterion]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertySelect]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyPerson]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyNumber]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyText]: {
        [FilterOperatorEnum.contains]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.doesNotContain]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyDate]: {
        [FilterOperatorEnum.isBefore]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isAfter]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.customPropertyBoolean]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.boolean,
    },
    [OpportunityFilterKeyEnum.owner]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.stakeholder]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.businessTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [OpportunityFilterKeyEnum.dataTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    // this is just for testing purposes
    [OpportunityFilterKeyEnum.createdById]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string,
    },
} as const satisfies FilterDefinitionsType<OpportunityFilterKeyEnumType>

//
// Data Products definitions
//

// TODO: Rename to DataProductFilterEnum ONE-1623
export const DataProductFilterKeyEnum = {
    title: 'title',
    dataProductType: 'dataProductType',
    keyword: 'keyword',
    valueScore: 'valueScore',
    valuePotential: 'valuePotential',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    dataTeam: 'dataTeam',
    businessTeam: 'businessTeam',
    stakeholder: 'stakeholder',
    area: 'area',
    opportunity: 'opportunity',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyPerson: 'customPropertyPerson',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyText: 'customPropertyText',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
    // Assessments
    assessmentOrdinal: 'assessmentOrdinal',
    assessmentNumerical: 'assessmentNumerical',
    // Dev purpose only
    createdById: 'createdById',
} as const
export type DataProductFilterKeyEnumType = keyof typeof DataProductFilterKeyEnum

export const DataProductOrderEnum = {
    dataProductType: 'dataProductType',
    title: 'title',
    valueScore: 'valueScore',
    valuePotential: 'valuePotential',
    priority: 'priority',
    phase: 'phase',
    owner: 'owner',
    lastCreated: 'lastCreated',
    lastUpdated: 'lastUpdated',
    customPropertySelect: 'customPropertySelect',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyText: 'customPropertyText',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
} as const
export type DataProductOrderEnumType = keyof typeof DataProductOrderEnum

export const defaultDataProductOrder = {
    key: OptionKeyEnum.order,
    valueString: DataProductOrderEnum.valuePotential,
    filterOperator: OrderOperatorEnum.desc,
} as const

export const DataProductGroupEnum = {
    ungrouped: 'ungrouped',
    dataProductType: 'dataProductType',
    keyword: 'keyword',
    priority: 'priority',
    phase: 'phase',
    valuePotential: 'valuePotential',
    valuePotentialCluster: 'valuePotentialCluster',
    valueScore: 'valueScore',
    owner: 'owner',
    businessTeam: 'businessTeam',
    dataTeam: 'dataTeam',
    stakeholder: 'stakeholder',
    area: 'area',
    opportunity: 'opportunity',
    // Custom Properties
    customPropertySelect: 'customPropertySelect',
    customPropertyPerson: 'customPropertyPerson',
    customPropertyNumber: 'customPropertyNumber',
    customPropertyDate: 'customPropertyDate',
    customPropertyBoolean: 'customPropertyBoolean',
    // Assessments
    assessmentNumerical: 'assessmentNumerical',
    assessmentOrdinal: 'assessmentOrdinal',
} as const
export type DataProductGroupEnumType = keyof typeof DataProductGroupEnum

export const DataProductParamKeyEnum = {
    ...DataProductFilterKeyEnum,
    ...DataProductGroupEnum,
    // Used by table layout
    assessment: 'assessment',
    customProperty: 'customProperty',
} as const
export type DataProductParamKeyEnumType = keyof typeof DataProductParamKeyEnum

export const dataProductFilterDefinitions = {
    [DataProductFilterKeyEnum.title]: {
        [FilterOperatorEnum.contains]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.doesNotContain]: EntityViewConfigValueTypeEnum.string,
    },
    [DataProductFilterKeyEnum.dataProductType]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [DataProductFilterKeyEnum.keyword]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.area]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.valueScore]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.valuePotential]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.priority]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [DataProductFilterKeyEnum.phase]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
    },
    [DataProductFilterKeyEnum.customPropertySelect]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyPerson]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyText]: {
        [FilterOperatorEnum.contains]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.doesNotContain]: EntityViewConfigValueTypeEnum.string,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyNumber]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyDate]: {
        [FilterOperatorEnum.isBefore]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isAfter]: EntityViewConfigValueTypeEnum.date,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.customPropertyBoolean]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.boolean,
    },
    [DataProductFilterKeyEnum.owner]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.dataTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.businessTeam]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.stakeholder]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.assessmentOrdinal]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isNot]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.assessmentNumerical]: {
        [FilterOperatorEnum.isGreaterThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isLessThan]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.greaterOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.lessOrEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isNotEqualTo]: EntityViewConfigValueTypeEnum.number,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
        [FilterOperatorEnum.isNotEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    [DataProductFilterKeyEnum.opportunity]: {
        [FilterOperatorEnum.includeAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.includeAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAnyOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.excludeIfAllOf]: EntityViewConfigValueTypeEnum.string_ary,
        [FilterOperatorEnum.isEmpty]: EntityViewConfigValueTypeEnum.none,
    },
    // this is just for testing purposes
    [DataProductFilterKeyEnum.createdById]: {
        [FilterOperatorEnum.is]: EntityViewConfigValueTypeEnum.string,
    },
} as const satisfies FilterDefinitionsType<DataProductFilterKeyEnumType>

//
// Helper functions and types
//

export function getFilterValueTypeByEntityType(
    entityCoreType: EntityCoreTypeEnum,
    key: string,
    operator: string
): EntityViewConfigValueTypeEnum {
    if (entityCoreType === EntityCoreTypeEnum.Opportunity) {
        return getFilterValueType<OpportunityFilterKeyEnumType>(
            opportunityFilterDefinitions,
            key as OpportunityFilterKeyEnumType,
            operator as FilterOperatorEnumType
        )
    }

    return getFilterValueType<DataProductFilterKeyEnumType>(
        dataProductFilterDefinitions,
        key as DataProductFilterKeyEnumType,
        operator as FilterOperatorEnumType
    )
}

export function getFilterValueType<Keys extends string>(
    definitions: FilterDefinitionsType<Keys>,
    key: Keys,
    operator: FilterOperatorEnumType
): EntityViewConfigValueTypeEnum {
    if (!(key in definitions)) throw new Error(`Key ${key} is not defined in provided definitions`)
    const definition = definitions[key]
    if (!(operator in definition)) throw new Error(`Operator "${operator}" is not supported for key "${key}"`)
    // Casting is safe because we check above if operator is in the definition
    return definition[operator] as EntityViewConfigValueTypeEnum
}

export function getFilterDefaultOperator<Keys extends string>(
    definitions: FilterDefinitionsType<Keys>,
    key: Keys
): FilterOperatorEnumType {
    if (!(key in definitions)) throw new Error(`Key ${key} is not defined in provided definitions`)
    const definition = definitions[key]
    return Object.keys(definition)[0] as FilterOperatorEnumType
}

// CheckedKeys verifies if TMap has all keys from TKeys
type CheckedKeys<TKeys extends string, TMap extends Record<TKeys, unknown>> = TMap

export type FilterDefinitionsType<Keys extends string> = Record<
    Keys,
    Partial<Record<FilterOperatorEnumType, EntityViewConfigValueTypeEnum>>
>

export type StringEnum<TKeys extends string> = { readonly [key in TKeys]: key }
