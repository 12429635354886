<script lang="ts" setup>
import { computed, toRef } from 'vue'

import AvatarGroup from 'primevue/avatargroup'
import { type AvatarPassThroughOptions } from 'primevue/avatar'
import type { PassThrough } from '@primevue/core'

import DIcon from './DIcon.vue'
import { useSizeMapping, type ExtendedSize } from '@/composables/sizing'

const props = withDefaults(
    defineProps<{
        users: { id?: string; photoUrl?: string | null; isActive?: boolean }[]
        size?: ExtendedSize
        selectedUserId?: string
        max?: number
    }>(),
    {
        size: 'md',
    }
)

const size = toRef(props, 'size')
const { rem: sizeRem } = useSizeMapping(size)

const avatarPT = computed<PassThrough<AvatarPassThroughOptions>>(() => ({
    root: {
        style: `height: ${sizeRem.value}; width: ${sizeRem.value}`,
        class: [
            'inline-flex justify-center items-center rounded-full text-slate-400 border border-slate-400',
            props.users.length > 0 ? 'bg-white' : 'bg-gray-200',
        ],
    },
    image: {
        class: ['rounded-full'],
    },
    icon: {
        class: ['rounded-full'],
    },
}))
</script>

<template>
    <AvatarGroup unstyled class="flex items-center -space-x-3">
        <template v-for="user in users.slice(0, max)" :key="user.id">
            <PAvatar v-if="user.isActive === false" unstyled :pt="avatarPT" class="border-dashed">
                <DIcon icon="user-x" :size="size" />
            </PAvatar>
            <PAvatar v-else-if="user.photoUrl" unstyled :image="user.photoUrl" :pt="avatarPT" class="border-none" />
            <PAvatar v-else unstyled :pt="avatarPT">
                <DIcon icon="user" :size="size" />
            </PAvatar>
        </template>
        <PAvatar v-if="!users.length" unstyled :pt="avatarPT" class="border-dashed">
            <DIcon icon="user" :size="size" />
        </PAvatar>
    </AvatarGroup>
</template>
