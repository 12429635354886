<script lang="ts" setup>
import { computed } from 'vue'
import type { Person, Entity } from '@prisma/client'

import { type SelectorItem } from '@/composables/selector'

import DAvatar from '@/components/DAvatar.vue'
import DButton, { type DButtonType } from '@/components/DButton.vue'
import DSelectorGeneric from '@/components/DSelectorGeneric.vue'
import DCommsMenu from '@/components/DCommsMenu.vue'

const NOT_SELECTED = 'not-selected'

const props = withDefaults(
    defineProps<{
        modelValue?: string
        people?: Pick<Person, 'id' | 'auth0Id' | 'name' | 'photoUrl' | 'email' | 'isActive'>[]
        placeholder?: string
        notSelectedLabel?: string
        filterPlaceholder?: string
        buttonType?: DButtonType
        testid?: string
        withCommsMenu?: boolean
        entity?: Pick<Entity, 'label' | 'id'>
        showLabel?: boolean
    }>(),
    {
        modelValue: NOT_SELECTED,
        buttonType: 'ghost',
        testid: 'd-selector-person',
        showLabel: true,
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const options = computed(() => {
    const mappedItems =
        props.people?.map((person) => ({
            id: person.id,
            label: person.name,
            auth0Id: person.auth0Id,
            name: person.name,
            email: person.email,
            photoUrl: person.photoUrl || '',
            isActive: person.isActive,
        })) ?? []
    return mappedItems
})

const showPlaceholder = computed(
    () => (!!props.placeholder && props.modelValue === NOT_SELECTED) || selectedUserNotPartOfOptions.value
)

const selectedUserNotPartOfOptions = computed(() => selected.value.length === 0)

const selected = computed(() => options.value.filter(({ id }) => id === props.modelValue))

const optionsOnlyActiveAndSelected = computed(() => {
    const inactiveSelected = selected.value.filter((person) => person.isActive === false)
    const activeOnly = options.value.filter((person) => person.isActive)

    return [...activeOnly, ...inactiveSelected]
})

function onModelValueUpdate(items: SelectorItem[]) {
    emit('update:modelValue', items[0]?.id === NOT_SELECTED ? undefined : items[0]?.id)
}

const shouldAddCommsMenu = computed(() => {
    return props.withCommsMenu && selected.value?.length
})
</script>

<template>
    <div :class="{ 'flex justify-between items-center overflow-hidden': shouldAddCommsMenu }">
        <div :class="{ 'flex-1 w-10': shouldAddCommsMenu }">
            <DSelectorGeneric
                :model-value="selected"
                :options="optionsOnlyActiveAndSelected"
                max-width="sm"
                :unselect-item-label="notSelectedLabel ?? $t('notAssigned')"
                :filter-placeholder="filterPlaceholder"
                :no-items-label="$t('components.SelectorItem.noPersonsFound')"
                :testid
                @select-item="emit('select', $event)"
                @unselect-item="emit('unselect', $event)"
                @update:model-value="onModelValueUpdate"
            >
                <slot>
                    <DButton :type="buttonType ?? 'ghost'" :data-testid="testid + '-button'">
                        <template v-if="showPlaceholder">
                            <span class="truncate text-slate-400">{{ placeholder }}</span>
                        </template>
                        <template v-else>
                            <span
                                v-tooltip="{
                                    value: selected[0]?.label,
                                }"
                                class="flex items-center gap-1 truncate"
                            >
                                <DAvatar v-if="selected[0]?.id" :users="[selected[0]]" size="md" />
                                <span
                                    v-if="showLabel"
                                    class="truncate"
                                    :class="!selected[0].isActive && 'text-slate-400'"
                                    >{{ selected[0]?.label }}</span
                                >
                            </span>
                        </template>
                    </DButton>
                </slot>
            </DSelectorGeneric>
        </div>
        <DCommsMenu v-if="shouldAddCommsMenu && entity" :people="selected" :entity />
    </div>
</template>
